import {
    useLocation,
    Navigate,
    Outlet,
} from 'react-router-dom';

import Home from './home/Home'
import About from './about/About'
import Admin from './admin/Admin'
import Game from './game/Game'
import Core from './core/Core'
import { useAuth } from '../contexts/AuthContext';
// import Overview from './overview/Overview'

const RequireAuth = () => {
    let location = useLocation();
    const { loggedIn } = useAuth()
    if (!loggedIn) {
        return <Navigate to="/" state={{ from: location }} />;
    }
    return <Outlet />;
}


const views = [
    { name: "Home", path: '/home', element: Home, protectedRoute: true, menuPart: 1 },
    { name: "About", path: '/about', element: About, protectedRoute: false, menuPart: 0 },
    { name: "Admin", path: '/admin', element: Admin, protectedRoute: true, menuPart: 2 },
    // { name: "Overview", path: '/overview', component: Overview, protectedRoute: true, menuPart: 2 },
    // { name: "SplashPage", path: '/splash', component: SplashPage, protectedRoute: false, },
]

export default views
export { Home, About, Admin, Game, Core, RequireAuth }