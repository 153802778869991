import React, { useState } from "react"
import { useAuth } from "../../contexts/AuthContext"
import './dialog.css'

const Header = (props) => {
    return (
        <div>
            <h1>{props.text}</h1>
        </div>
    )
}

const Footer = (props) => {
    return <div><h3>{props.text}</h3></div>
}

const Input = (props) => {
    // const [inputVal, setInputVal] = useState("")

    const changeHandler = (e) => {
        // setInputVal(e.target.value)
        props.parentFunction(e.target.value)
    }

    return (
        <div>
            <label>{props.labelName}</label>
            <input type={props.inputType} id={props.id} onChange={changeHandler} />
        </div>
    )
}

const LoginForm = (props) => {

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const { signin } = useAuth()

    const clickHandler = () => {
        // put your own code here
        // alert(`Username: ${email} Password: ${password}`)
        signin(email, password)
    }


    return (
        <div>
            <Input id="email" labelName="Email: " inputType="email" parentFunction={setEmail} />
            <Input id="password" labelName="Password: " inputType="password" parentFunction={setPassword} />
            <button onClick={clickHandler}>Login</button>
        </div>

    )
}

const SignupForm = (props) => {

    const [username, setUsername] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [password2, setPassword2] = useState("")

    const { signup } = useAuth()

    const clickHandler = () => {
        // put your own code here
        // alert(`Username: ${email} Password: ${password}`)
        signup(email, password)
    }


    return (
        <div>
            <Input id="username" labelName="Username: " inputType="text" parentFunction={setUsername} />
            <Input id="email" labelName="Email: " inputType="email" parentFunction={setEmail} />
            <Input id="password" labelName="Unique Password: " inputType="password" parentFunction={setPassword} />
            <Input id="password2" labelName="Identical Password: " inputType="password" parentFunction={setPassword} />
            <button onClick={clickHandler}>Create Account</button>
        </div>

    )
}

const Content = (props) => {
    const [dialogState, setDialogState] = useState('signin')


    return (
        <div>
            <h2>{props.title}</h2>
            {dialogState === 'signin'
                ? <LoginForm />
                : <SignupForm />
            }

            {dialogState === 'signin'
                ? `Don't have an account? Create one now.`
                : 'Already have an account? Login now'
            }


            {dialogState === 'signin'
                ? <button onClick={() => { setDialogState('signup') }}>Sign up</button>
                : <button onClick={() => { setDialogState('signin') }}>Sign in</button>
            }
        </div>
    )
}

const Main = ({ children }) => {
    return (
        <div>
            <Header text="Login" />
            <Content title="Enter your credentials" />
            <Footer text="" />
            {children}
        </div>
    )
}
export default Main