import React from 'react'
import Letter from './Letter'

const Letters = ({letters, ...props}) => {
    return (
        <div {...props} style={{display:'flex'}}>
            {[...letters].map((letter,index)=><Letter key={`${index}${letter}`} letter={letter}/>)}
        </div>
    )
}

export default Letters