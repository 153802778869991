import React, { useState, useEffect } from 'react';
import Login from '../../components/Login';
import { Link, Navigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import './home.css'
import logo from './logo.png'
import Letters from '../../components/letters/Letters';
import LoginDialog from '../../components/loginDialog/Dialog';
import { socket } from '../../SocketAPI';

function App() {
    const { loggedIn, socketAuthenthicated, idToken } = useAuth()
    console.log({ socketAuthenthicated })
    console.log(idToken)
    console.log('Logged in is: ' + loggedIn);

    const [dialogOpen, setDialogOpen] = useState(false)

    useEffect(() => {
        const testHandler = data => console.log(data)
        !socket.hasListeners('test') && socket.on('test', testHandler)
        return () => {
            socket.off('test', testHandler)
        }
    })



    useEffect(() => {
        socketAuthenthicated && socket.emit("main", {
            controller: 'core',
            action: 'testAction'
        }, data => {
            console.log(data)
        })
    }, [socketAuthenthicated])



    if (loggedIn) return <Navigate to="/core" />
    return (
        <div id="home">
            <div id="header">
                <div id="centerWrapper">
                    <div className="header" id="left">
                        <Link to="/about">
                            <Letters onClick={() => { console.log("about") }} letters="about" />
                        </Link>
                    </div>
                    <div className="header" id="center">
                        <div className="title">
                            <Letters letters="opus" />

                        </div>
                        <div id="logoWrapper">
                            <img id="logo" src={logo} alt="My logo" />
                        </div>
                        <div className="title">
                            <Letters letters="vrye" />
                        </div>
                    </div>
                    <div className="header" id="right">
                        <Letters onClick={() => { setDialogOpen(true) }} letters="login" />

                    </div>
                </div>

            </div>

            <div id="main">
                <div id={`loginDialog`} className={`${dialogOpen ? 'open' : ''}`} >

                    <div id="dialogContent">
                        {/* <input type={"text"}></input>
                    <input type={""}></input>
                    
                    <div></div>
                    <div></div>
                    <div></div> */}

                        <LoginDialog>
                            <Login />
                            <button onClick={() => { setDialogOpen(false) }}>Close</button>
                        </LoginDialog>

                        <div>

                        </div>
                    </div>

                </div>


            </div>

            {/* <Login /> */}

        </div>
    );
}

export default App;