import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Divider, ListSubheader } from '@mui/material';

function generate(element) {
    return [0, 1, 2, 3].map((value) =>
        React.cloneElement(element, {
            key: value,
        }),
    );
}
function generate15(element) {
    return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17].map((value) =>
        React.cloneElement(element, {
            key: value,
        }),
    );
}

const Info = ({ styleProp }) => {

    return <div style={styleProp}>

        <List dense={true} subheader={<ListSubheader>Cur</ListSubheader>}>
            {generate(
                <ListItem>
                    <ListItemText
                        primary="Single-line item"
                        
                    />
                    <ListItemText
                        primary="5"
                        sx={{ textAlign: 'right' }}
                    />
                </ListItem>,
            )}
        </List>
        <Divider />
        <List dense={true} subheader={<ListSubheader>Res</ListSubheader>}>
            {generate15(
                <ListItem>
                    <ListItemText
                        primary="Another"
                        secondary="A Tier 1"
                    />
                    <ListItemText
                        primary="5000"
                        sx={{ textAlign: 'right' }}
                    />
                </ListItem>,
            )}

        </List>

    </div>
}

export default Info