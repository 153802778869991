import * as React from 'react';

import { styled, useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MuiAppBar from '@mui/material/AppBar';

import useMediaQuery from '@mui/material/useMediaQuery';
import { Button } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';

const drawerWidth = 240;

const MiniAppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const CustomAppBar = ({ drawerOpen, setDrawerOpen }) => {
  const matchesSM = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  const SelectedAppBar = matchesSM ? MiniAppBar : MuiAppBar

  const { signout } = useAuth()

  return (
    <SelectedAppBar position="fixed" open={drawerOpen}>
      <Toolbar>
        {matchesSM && <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => { setDrawerOpen(true) }}
          edge="start"
          sx={{
            marginRight: '36px',
            ...(drawerOpen && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>}
        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
          Opus Vrye
        </Typography>
        <Button color="inherit" onClick={() => { signout() }}>Logout</Button>
      </Toolbar>
    </SelectedAppBar>
  )
}

export default CustomAppBar