import React from 'react';

const Admin = () => {



    return (
        <div>test Admin</div>
    )
}

export default Admin