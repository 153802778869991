import { useContext, useState, useEffect, createContext } from 'react';
import { socket } from '../SocketAPI';

const CoreContext = createContext();

export function useCore() {
    return useContext(CoreContext);
}

export function CoreProvider({ children }) {
    const [loading, setLoading] = useState(true);
    const [character, setCharacter] = useState({})
    const [user, setUser] = useState({})

    useEffect(() => {
        const coreInit = data => {
            console.log(data)
            const {
                character = {},
                user = {}
            } = data

            setUser(user)
            setCharacter(character)
            setLoading(false)
        }
        !socket.hasListeners('coreInit') && socket.on('coreInit', coreInit)
        // socket.emit('main', {
        //     controller: 'core',
        //     action: 'loadChracter',
        // })
        return () => {
            socket.off('coreInit', coreInit)
            // potentially empty the character object.
        }
    }, [])


    const value = {
        user,
        loading,
        character,
        setCharacter,
    };
    return (
        <CoreContext.Provider value={value}>
            {children}
        </CoreContext.Provider>
    );
}
