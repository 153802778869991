import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './contexts/AuthContext';
import { CoreProvider } from './contexts/CoreContext';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// const customThemeObj = {
//   palette: {
//     type: 'dark',
//     primary: {
//       main: '#6d4c41',
//       contrastText: '#90a4ae',
//     },
//     secondary: {
//       main: '#78909c',
//       contrastText: '#546e7a',
//     },
//     background: {
//       default: '#78909c',
//       paper: '#ffd180',
//     },
//     text: {
//       primary: '#ef5350',
//     },
//   },
// }

const theme = createTheme();

ReactDOM.render(
  <React.StrictMode>
    <CoreProvider>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </AuthProvider>
    </CoreProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
