import * as React from 'react';
import { styled } from '@mui/material/styles';
// import Box from '@mui/material/Box';
// import Paper from '@mui/material/Paper';
// import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Main from './main/Main';
import AppBar from './AppBar'
import Drawer from './Drawer'
import UserCreationDialog from './UserCreationDialog'

const BasicGrid = () => {


  const [drawerOpen, setDrawerOpen] = React.useState(false);
  console.log(drawerOpen)

  

  return (
    <Box sx={{ display: 'flex' }}>
      <UserCreationDialog />
      <AppBar drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
      <Drawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
      <Main />
    </Box>
  )
  
  
}

export default BasicGrid





/*
<Box sx={{ flexGrow: 1, height: '100%', padding: '16px 16px 0px 16px' }}>
      <Grid sx={{height:'100%'}} container spacing={2}>
        <Grid item xs={8} >
          <Item>xs=8</Item>
        </Grid>
        <Grid item xs={4}>
          <Item>xs=4</Item>
        </Grid>
        <Grid item xs={4}>
          <Item>xs=4</Item>
        </Grid>
        <Grid item xs={8} sx={{flex: '2 0 400px'}}>
          <Item>xs=8</Item>
        </Grid>
        <Grid item xs={3}>
          <Item>xs=8</Item>
        </Grid>
        <Grid item xs={3}>
          <Item>xs=8</Item>
        </Grid>
        <Grid item xs={3}>
          <Item>xs=8</Item>
        </Grid>
        <Grid item xs={3}>
          <Item>xs=8</Item>
        </Grid>
      </Grid>
    </Box>


    const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: '100%',
}));
    
    */

