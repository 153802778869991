import React from 'react';
import './App.css';
import {
  BrowserRouter as Router, Routes,
  Route
} from "react-router-dom";
import { About, Home, Admin, Core, RequireAuth } from './views/views';


const App = () => {

  
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />}>

        </Route>
        <Route path="/about" element={<About />} />
        <Route element={<RequireAuth />}>
          <Route path="/core" element={<Core />} />
          <Route path="/admin" element={<Admin />} />
        </Route>

        <Route path="*" element={<div>Not found</div>} />
      </Routes>
    </Router>

  );
}

export default App;
