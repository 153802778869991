import { useContext, useState, useEffect, createContext } from 'react';

import { auth, firestore } from '../adapters/firebase';
import { socket } from '../SocketAPI';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);
  // const [socketConnected, setSocketConnected] = useState(false)
  const [idToken, setIdToken] = useState(null)
  const [socketAuthenthicated, setSocketAuthenthicated] = useState(false)
  const { email, uid } = currentUser || {}
  // const lastLoginTimestampToDate = lastLoginTimestamp ? lastLoginTimestamp.toDate() : ''


  const signup = (email, password) => {
    let promise = new Promise(function (resolve, reject) {
      auth
        .createUserWithEmailAndPassword(email, password)
        .then((ref) => {
          // ref.user.updateProfile({
          //   displayName: fullName,
          // });
          resolve(ref);
        })
        .catch((error) => reject(error));
    });

    return promise;
  };

  const signin = (email, password) => {
    let promise = new Promise(function (resolve, reject) {
      auth
        .signInWithEmailAndPassword(email, password)
        .then((ref) => {
          resolve(ref);
        })
        .catch((error) => {
          reject(error);
        });
    });

    return promise;
  };

  const signout = () => {
    return auth.signOut();
  };

  const passwordReset = (email) => {
    let promise = new Promise(function (resolve, reject) {
      auth
        .sendPasswordResetEmail(email)
        .then(() => {
          resolve(`Password Reset Email sent to ${email}`);
        })
        .catch((error) => {
          reject(error);
        });
    });

    return promise;
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const idToken = await user.getIdToken()
        localStorage.setItem('idToken', idToken)
        const { uid } = user || {}
        if (uid) {
          setCurrentUser(user)
          setIdToken(idToken)
        } else {
          setCurrentUser({})
          setIdToken(null)
        }
      } else {
        setCurrentUser({})
        setIdToken(null)
      }
      setLoading(false);
    });
    return unsubscribe;
  }, [uid, setCurrentUser]);

  useEffect(() => {
    if (idToken && setSocketAuthenthicated) {
      console.log("sending idToken")

      const connectHandler = () => {
        console.log("Received connect and trying to authenthicate")
        socket.emit('authenticate', { token: idToken }, async data => {
          if (data.code === 'auth/id-token-expired') {
            const idToken = await auth.currentUser.getIdToken()
            setIdToken(idToken)
          }
          if (data === true) {
            console.log("Successfully authenticated")
            setSocketAuthenthicated(true)
          }
        })
      }

      const disconnectHandler = () => {
        socket.connect()
      }

      !socket.hasListeners('connect') && socket.on('connect', connectHandler);
      !socket.hasListeners('disconnect') && socket.on('disconnect', disconnectHandler);


      return () => {
        socket.off('connect', connectHandler)
        socket.off('disconnect', disconnectHandler)
      }


    }
  }, [idToken, setSocketAuthenthicated])

  // useEffect(() => {
  //   if (idToken) {
  //     console.log("sending idToken")
  //     socket.emit("authenthicate", idToken, authenthicated => {
  //       console.log("getting return with value", authenthicated)
  //       setSocketAuthenthicated(authenthicated)
  //     })
  //   }
  // }, [idToken])

  // useEffect(() => {
  //   const connectHandler = connected => {
  //     setSocketConnected(true)
  //   }
  //   const disconnectHandler = disconnected => {
  //     setSocketConnected(false)
  //   }
  //   !socket.hasListeners('connect') && socket.on('connect', connectHandler)
  //   !socket.hasListeners('disconnect') && socket.on('disconnect', disconnectHandler)

  //   return () => {
  //     console.log('unmounting connectHandler')
  //     socket.off('connect', connectHandler)
  //     socket.off('disconnect', disconnectHandler)
  //   }
  // }, [])

  // useEffect(() => {
  //   const authenthicateConnectionHandler = () => {



  //   }
  // }, [auth.currentUser, socketConnected])




  // useEffect(() => {

  //   const connectHandler = connected => {
  //     if (auth.currentUser && connected) {
  //       const idToken = await auth.currentUser.getIdToken()
  //       socket.emit("authenthicate", idToken, authenthicated => {
  //         console.log("getting return with value", authenthicated)
  //         if (authenthicated.code === "auth/id-token-expired") {
  //           console.log("trying to generate new ID token")
  //           auth.currentUser.getIdToken().then(idToken => setIdToken(idToken)) // verify later it works.  
  //         }
  //         setSocketAuthenthicated(authenthicated)
  //       })
  //     }
  //   }

  //   !socket.hasListeners('connected') && socket.on('connected', connectHandler)
  //   return () => {
  //     console.log('unmounting connectHandler')
  //     socket.off('connected', connectHandler)
  //   }
  // }, [auth.currentUser])



  // useEffect(() => {
  //   const connectHandler = connected => {
  //     if (idToken && connected) {
  //       console.log("sending id token")
  //       socket.emit("authenthicate", idToken, authenthicated => {
  //         console.log("getting return with value", authenthicated)
  //         if (authenthicated.code === "auth/id-token-expired") {
  //           console.log("trying to generate new ID token")
  //           auth.currentUser.getIdToken().then(idToken => setIdToken(idToken)) // verify later it works.  
  //         }
  //         setSocketAuthenthicated(authenthicated)
  //       })
  //     }
  //   }
  //   !socket.hasListeners('connected') && socket.on('connected', connectHandler)
  //   return () => {
  //     console.log('unmounting connectHandler')
  //     socket.off('connected', connectHandler)
  //   }
  // }, [idToken])




  // console.log({ email })
  const loggedIn = email ? true : false

  const value = {
    loading,
    currentUser,
    idToken,
    signup,
    signin,
    signout,
    passwordReset,
    loggedIn,
    auth,
    socketAuthenthicated
  };
  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
