import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { TextField } from '@mui/material';
import { useCore } from '../../contexts/CoreContext';
import { socket } from '../../SocketAPI';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs() {

    const { character, loading, setCharacter } = useCore()
    const [name, setName] = React.useState('')

    console.log(character && character.id === undefined)
    console.log({ character })
    console.log({ loading })

    const open = (character === null || character.id === undefined) && loading === false ? true : false;
    console.log({ open })

    const createCharacter = () => {
        console.log("Running create character function")
        socket.emit('main', {
            controller: 'core',
            action: 'createCharacter',
            payload: { name }
        }, ({ character }) => {
            console.log(character)
            setCharacter(character)
        })
    }

    return (

        <BootstrapDialog
            onClose={() => { }}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <BootstrapDialogTitle id="customized-dialog-title"
                onClose={() => { }}
            >
                Create your character
            </BootstrapDialogTitle>
            <DialogContent dividers>
                <Typography gutterBottom>
                    Welcome to the world of Opus Vrye.
                </Typography>

                <Typography gutterBottom>
                    Your first step is to make a name of yourself, what shall we call you?
                </Typography>

                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Character Name"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={name}
                    onChange={e => { setName(e.target.value) }}
                    required
                />
                <Typography gutterBottom>
                    Please in naming yourself, please adhere to all chat rules, I said please.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={createCharacter} >
                    Create Character
                </Button>
            </DialogActions>
        </BootstrapDialog>

    );
}
