import firebase from 'firebase/compat/app';

import 'firebase/compat/auth';        // for authentication
// import 'firebase/compat/storage';     // for storage
// import 'firebase/compat/database';    // for realtime database
// import 'firebase/compat/firestore';   // for cloud firestore
// import 'firebase/compat/messaging';   // for cloud messaging
// import 'firebase/compat/functions';   // for cloud functions



// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID,
//   measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
// };

const firebaseConfig = {
  apiKey: "AIzaSyD5fOjtlkOw-BC6e0P330sH4xcdEsVYIBk",
  authDomain: "opusvrye-1.firebaseapp.com",
  projectId: "opusvrye-1",
  storageBucket: "opusvrye-1.appspot.com",
  messagingSenderId: "47898115718",
  appId: "1:47898115718:web:ac6c8bc098354c72bc5b07",
  measurementId: "G-ZMZVBB50L7"
};

const myApp = firebase.initializeApp(firebaseConfig);

export const auth = myApp.auth();

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'select_account' });

export const signInWithGoogle = () => auth.signInWithPopup(provider);

export default firebase;

