import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Paper, styled } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import SwipeableViews from 'react-swipeable-views';
import BottomNavigation from '../bottomNavigation/BottomNavigation'
import Log from '../log/Log';
import Chat from '../chat/Chat';
import Info from '../info/Info';
import Profile from '../profile/Profile'
import './main.css'


const PlaceHolder = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const MobileView = ({ slideIndex, setSlideIndex }) => {
    return (
        <SwipeableViews containerStyle={{ height: '100%' }} onChangeIndex={index => { setSlideIndex(index) }} index={slideIndex} style={{ flex: '1' }} enableMouseEvents>
            <Info />
            <Box sx={{ flexGrow: 1, p: 2 }}>
                <Typography paragraph>
                    Consequat mauris nunc congue nisi vitae suscipit. Fringilla est ullamcorper
                    eget nulla facilisi etiam dignissim diam. Pulvinar elementum integer enim
                    neque volutpat ac tincidunt. Ornare suspendisse sed nisi lacus sed viverra
                    tellus. Purus sit amet volutpat consequat mauris. Elementum eu facilisis
                    sed odio morbi. Euismod lacinia at quis risus sed vulputate odio. Morbi
                    tincidunt ornare massa eget egestas purus viverra accumsan in. In hendrerit
                    gravida rutrum quisque non tellus orci ac. Pellentesque nec nam aliquam sem
                    et tortor. Habitant morbi tristique senectus et. Adipiscing elit duis
                    tristique sollicitudin nibh sit. Ornare aenean euismod elementum nisi quis
                    eleifend. Commodo viverra maecenas accumsan lacus vel facilisis. Nulla
                    posuere sollicitudin aliquam ultrices sagittis orci a.
                </Typography>
            </Box>
            <Chat />
            <Log />
        </SwipeableViews>
    )
}

const DesktopView = () => {
    return (
        <div id="GameWrapper">
            <Paper elevation={3} id="ProfileWrapper">
                <div className="paperHeader">
                    {`Profile`}
                    <Box sx={{ flexGrow: 1 }} />
                </div>
                <Profile />
            </Paper>
            <div id="InfoWrapper">
                <Info />
            </div>
            <div id="ViewWrapper">
                <Box sx={{ flexGrow: 1, p: 2 }}>
                    <Typography paragraph>
                        Consequat mauris nunc congue nisi vitae suscipit. Fringilla est ullamcorper
                        eget nulla facilisi etiam dignissim diam. Pulvinar elementum integer enim
                        neque volutpat ac tincidunt. Ornare suspendisse sed nisi lacus sed viverra
                        tellus. Purus sit amet volutpat consequat mauris. Elementum eu facilisis
                        sed odio morbi. Euismod lacinia at quis risus sed vulputate odio. Morbi
                        tincidunt ornare massa eget egestas purus viverra accumsan in. In hendrerit
                        gravida rutrum quisque non tellus orci ac. Pellentesque nec nam aliquam sem
                        et tortor. Habitant morbi tristique senectus et. Adipiscing elit duis
                        tristique sollicitudin nibh sit. Ornare aenean euismod elementum nisi quis
                        eleifend. Commodo viverra maecenas accumsan lacus vel facilisis. Nulla
                        posuere sollicitudin aliquam ultrices sagittis orci a.
                    </Typography>
                </Box>


            </div>
            <div id="LogWrapper"><Log /></div>
            <div id="BoostWrapper"> <Log /></div>
            <div id="ChatWrapper"> <Chat /></div>


            {/* <button className="button signout" onClick={() => signout()}>Sign out</button> */}
        </div>
    )
}

const Main = () => {
    const [slideIndex, setSlideIndex] = React.useState(1)
    const matchesXS = useMediaQuery((theme) => theme.breakpoints.up('xs'));
    const matchesSM = useMediaQuery((theme) => theme.breakpoints.up('sm'));
    const matchesMD = useMediaQuery((theme) => theme.breakpoints.up('md'));
    // console.log({ matchesXS, matchesSM, matchesMD })
    const showBottomNavigation = false;
    // const stylePropXS = { height: `calc(100vh${showBottomNavigation ? '- 56px' : ''})` }

    const SelectedView = matchesSM ? DesktopView : MobileView

    return (

        <Box component="main" sx={{ flexGrow: 1, p: 2, flexDirection: 'column', height: '100vh', overflow: 'auto' }}>
            <PlaceHolder />
            <Box sx={{ flexGrow: 1, display: 'flex', height: 'calc(100% - 64px)' }}>
                <SelectedView slideIndex={slideIndex} setSlideIndex={setSlideIndex} />
            </Box>
            {showBottomNavigation && <BottomNavigation index={slideIndex} setIndex={setSlideIndex} />}

        </Box>

    )
}

export default Main